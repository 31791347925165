.container_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}

.contact_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    font-size: 3rem;
}

.contact_title::after {
    content: "";
    width: 150%;
    border-radius: 15px;
    height: 5px;
    margin-top: 50px;
    background: rgb(14, 74, 149);
    margin-bottom: 60px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
}

.holder {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}

.input {
    font-size: 2rem;
    margin: 0  auto;
    width: 50%;
    background: transparent;
    border: 2px solid black;
    margin-bottom: 50px;
    outline: none;
    padding-bottom: 10px;
    padding-left: 10px;
    
}

.input:focus {
    border: 2px solid rgb(14, 74, 149) !important;
}

.input::placeholder{
    font-size: 1.5rem;
}

.input_message{
    height: 100px;
}

.submit {
    display: flex;
    width: 300px;
    text-transform: uppercase;
    color: white;
    border: none;
    padding: 7.5px 24px 6px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 2rem;
    box-shadow: 0 0 40px 40px rgb(14, 74, 149) inset, 0 0 0 0 rgb(14, 74, 149);
    transition: all 150ms ease-in-out;
}

.submit:hover {
    box-shadow: 0 0 10px 0 rgb(14, 74, 149) inset, 0 0 10px 4px rgb(14, 74, 149);
    color: rgb(14, 74, 149);
  
  }


@media (max-width:900px) {
    .holder {
        flex-direction: column;
    }

    .input{
        width: 100%;
    }
}

@media (max-width:380px) {
    
    .contact_title{
        font-size: 2rem;
    }

    .input{
        font-size: 1rem;
        padding-top: 10px;
    }

    .submit{
        font-size: 1rem;
        width: 200px;
    }
}
